import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
//Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
//Bio
import gameTournament from "../images/game-tournament.jpg"
//Projects
import cloudstands from "../images/cloudstands.png"
import takeNotes from "../images/takenotes.png"
import gameOfLife from "../images/gameoflife.png"
//Technologies
import reactIcon from "../images/React-icon.svg"
import materialUI from "../images/Material-ui.svg"
import node from "../images/Node.svg"
import postgreSQL from "../images/PostgreSQL.svg"
import reduxForms from "../images/Redux-Forms.png"
import reduxIcon from "../images/Redux-icon.svg"
import JavascriptLogo from "../images/JavascriptLogo.png"
import styledComponentsIcon from "../images/Styled-Components.png"

library.add(fab, faEnvelope, faFileAlt)

const IndexPage = ({ data }) => (
  <Layout>
    <div>
      <HeaderText>Recent Work</HeaderText>
      <LayoutFix>
        <ProjCardWrapper className="card-wrap">
          <h1 className="title">Cloud Stands</h1>
          <img className="project-img" alt="Cloud Stands" title="Cloud Stands" src={cloudstands} />
          <TextWrapper>
            <div>
              <a target="__blank" rel="noopener noreferrer" style={{ color: "white", textDecoration: "none" }} href="https://github.com/labs14-market-organizer"><h4>Github</h4></a>
              <a target="__blank" rel="noopener noreferrer" style={{ color: "white", marginLeft: "20px", textDecoration: "none" }} href="https://cloudstands.com/"><h4>Deployed</h4></a>
            </div>
            <p>Cloud Stands facilitates growth in farmers markets by allowing Market Owners to digitally manage their marketplaces and Vendors to search for markets and rent space to sell their goods.</p>
          </TextWrapper>
          <Techbg>
            <h1 className="tech">Built with</h1>
          </Techbg>
          <TechWrapper>
            <div>
              <img src={reactIcon} alt="React" title="React" />
            </div>
            <div>
              <img src={JavascriptLogo} alt="Javascript" title="Javascript" />
            </div>
            <div>
              <img src={reduxIcon} alt="Redux" title="Redux" />
            </div>
            <div>
              <img src={materialUI} alt="Material UI" title="Material UI" />
            </div>
            <div>
              <img src={styledComponentsIcon} alt="Styled Components" title="Styled Components" />
            </div>
            <div>
              <img src={reduxForms} alt="Redux Forms" title="Redux Forms" />
            </div>
            <div>
              <img src={node} alt="Node" title="Node" />
            </div>
            <div>
              <img src={postgreSQL} alt="PostgreSQL" title="PostgreSQL" />
            </div>
          </TechWrapper>
        </ProjCardWrapper>
        <ProjCardWrapper className="card-wrap">
          <h1 className="title">TakeNotes</h1>
          <img className="project-img" alt="TakeNotes" title="TakeNotes" src={takeNotes} />
          <TextWrapper>
            <div>
              <a target="__blank" className="contact-type" rel="noopener noreferrer" style={{ color: "white", textDecoration: "none" }} href="https://github.com/hammettDev/front-end-project-week"><h4>Git FE</h4></a>
              <a target="__blank" rel="noopener noreferrer" style={{ color: "white", textDecoration: "none", marginLeft: "20px" }} href="https://github.com/hammettDev/back-end-project-week"><h4>Git BE</h4></a>
              <a target="__blank" rel="noopener noreferrer" style={{ color: "white", marginLeft: "20px", textDecoration: "none" }} href="https://takenotesapp.netlify.com/"><h4>Deployed</h4></a>
            </div>
            <p>TakeNotes is a Full-Stack Web Application that allows users to create an account and store their notes as well as allow them to view and save notes written by other users.</p>
          </TextWrapper>
          <Techbg>
            <h1 className="tech">Built with</h1>
          </Techbg>
          <TechWrapper>
            <div>
              <img src={reactIcon} alt="React" title="React" />
            </div>
            <div>
              <img src={JavascriptLogo} alt="Javascript" title="Javascript" />
            </div>
            <div>
              <img src={styledComponentsIcon} alt="Styled Components" title="Styled Components" />
            </div>
            <div>
              <img src={node} alt="Node" title="Node" />
            </div>
            <div>
              <img src={postgreSQL} alt="PostgreSQL" title="PostgreSQL" />
            </div>
          </TechWrapper>
        </ProjCardWrapper>
        <ProjCardWrapper className="card-wrap">
          <h1 className="title">Game of Life</h1>
          <img className="project-img" alt="Game of Life" title="Game of Life" src={gameOfLife} />
          <TextWrapper>
            <div>
              <a target="__blank" rel="noopener noreferrer" style={{ color: "white", textDecoration: "none" }} href="https://github.com/hammettDev/Conways-Life"><h4>Github</h4></a>
              <a target="__blank" rel="noopener noreferrer" style={{ color: "white", marginLeft: "20px", textDecoration: "none" }} href="https://game-of-life-three.now.sh/"><h4>Deployed</h4></a>
            </div>
            <p>The Game of Life, also known simply as Life, is a cellular automaton devised by the British mathematician John Horton Conway in 1970.</p>
          </TextWrapper>
          <Techbg>
            <h1 className="tech">Built with</h1>
          </Techbg>
          <TechWrapper>
            <div>
              <img src={reactIcon} alt="React" title="React" />
            </div>
            <div>
              <img src={JavascriptLogo} alt="Javascript" title="Javascript" />
            </div>
            <div>
              <img src={styledComponentsIcon} alt="Styled Components" title="Styled Components" />
            </div>
          </TechWrapper>
        </ProjCardWrapper>
      </LayoutFix>
    </div>
    <Bio className="second">
      <Contact>
        <div>
          <h2 style={{ marginBottom: "-16px" }}>Contact Me</h2>
          <div className="contact-type" style={{ display: "flex", flexDirection: "row", marginBottom: "-20px" }}>
            <a style={{ display: "flex" }} target="_blank" rel="noopener noreferrer" href="https://github.com/hammettDev" >
              <FontAwesomeIcon style={{ marginRight: "6px" }} icon={['fab', 'github']} />
              <h3>hammettDev</h3></a>
          </div>
          <div className="contact-type" style={{ display: "flex", flexDirection: "row", marginBottom: "-20px" }}>
          <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/justin-hammett-stl/" >
            <FontAwesomeIcon style={{ marginRight: "6px" }} icon={['fab', 'linkedin']} />
            <h3>justin-hammett-stl</h3></a>
          </div>
          <div className="contact-type" style={{ display: "flex", flexDirection: "row" }}>
          <a target="__blank" rel="noopener noreferrer" href="mailto:justin@hammett.dev">
            <FontAwesomeIcon style={{ marginRight: "6px" }} icon={faEnvelope} />
            <h3>justin@hammett.dev</h3></a>
          </div>
          <div className="contact-type" style={{ display: "flex", flexDirection: "row",  }}>
          <a className="resume" target="__blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1kCPFueLzGo3kA6XF4YqYSOrrsumP1njw/view?usp=sharing">
            <FontAwesomeIcon style={{  marginRight: "6px", fontSize: '40px', marginTop: '-20px'   }}  icon={faFileAlt} />
            <h3 style={{marginTop: "-10px"}}>Resume</h3></a>
          </div>
        </div>
      </Contact>
      <div className="bio">
        <h4>
          Hello, I'm Justin Hammett.
    </h4>
        <p>
          I'm a UX Minded Full Stack Engineer with a background in Design and Data Entry.
<br />
          <br />
          If I am not slinging code for a project or helping out with students, you can find me on twitch pwning n00bz or working on my mixtape.
  </p>
        <img alt="Halo Tournament" src={gameTournament} />
      </div>

    </Bio>
  </Layout>
)
export default IndexPage

//styles 



const Contact = styled.div`
display: flex;
flex-direction: column;
order: 2;

@media(max-width: 990px) {
  order: 1;
  margin-left: 30px;
  }
h3 {
  margin: 0px;
padding: 0px;
color: white;
font-weight: 400;
font-size: 1.0rem;
}


.contact-type {
  display: flex;
  :hover {
  color: silver;
  h3   {
    color: silver;
  }
}
}

`


const Bio = styled.div`
color: white;
font-family: "Roboto";
margin-top: -160px;
  font-size: 1.8rem;
  display:flex;
  flex-direction: column;
  font-weight: 400;
  margin-left: 20%;
  margin-right: 10vw;
  align-items: flex-end;



}
h4 {
  font-size: 1.6rem;
}
p {
  font-weight: 200;
  font-size: 1.2rem;
  width: 400px;

}
div {
  margin-top: 30px;
  margin-bottom: 10px;
  
  order: 2;
  align-items: center;
  
}

}
a {
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;

  :hover {
    color: silver;
  }
}
img {

  height: 230px;
}


}
@media (max-width: 1610px) {

  margin-left: 30px;
}



@media (max-width: 1470px) {
flex-direction: row;
align-items: flex-start;
justify-content: space-around;
margin-top: 40px;


}
@media (max-width: 1170px) {
flex-direction: row;
align-items: flex-start;
justify-content: space-around;
margin-top: 40px;


}
@media (max-width: 990px) {
display: flex;
background-color: initial;
margin-top: -200px;
flex-direction: column;
align-items: center;
flex-wrap: wrap;
margin: 0 auto;
padding: 0;

.bio {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {

width: 80%;
  }
}
}
`


const ProjCardWrapper = styled.div`
display: flex;
width: 300px;
flex-direction: column; 
box-shadow: 10px 10px 41px 0px rgba(0,0,0,0.75);
background-color: black;

:hover {
            
            transition: .2s;
            transform: scale(1.025);
            animation: glowing 2000ms infinite;
          
            
            @keyframes glowing {
                0% {
                    box-shadow: 0 0 5px #11A3E1;
                }
                50% {
                    box-shadow: 0 0 20px;
                }
                100% {
                    box-shadow: 0 0 5px white;
                }
            }
        }

.project-img {
  height: 120px;
  width: 100%;
}

.tech {
  color: white;
  text-align: center;
  font-weight: 300;
  margin-top: -60px;
  margin-bottom:50px;
  padding-top: 70px;
  
  h1{
    
  }
}

.title {
    color: white;
    background-color: #2B2B2B;
    padding-bottom: 16px;
    margin-bottom: 0;
    padding-top: 16px;
    text-align: center;
    font-weight: 500;

  }

`



const TechWrapper = styled.div`
display:flex;
height: 200px;
margin-top: -30px;
padding-top: 20px;
background-color: #2B2B2B;
justify-content: space-around;
flex-wrap: wrap;
box-sizing: border-box;
border-bottom: 6px solid black;
border-right: 4px solid black;
border-left: 4px solid black;
border-radius: 6px;


div {
  
  margin: 4px;
  img {
    
    width: 55px;
    fill: none;
  stroke: #646464;
  stroke-width: 1px;
  stroke-dasharray: 2,2;
  stroke-linejoin: round;
    
  }
}
`

const LayoutFix = styled.div`
display:flex;
flex-direction: row;
background-color: silver;
filter: opacity(80%);


h1 {
  color: #11A3E1;
  font-size: 1.6rem;
  }
  .card-wrap {
margin: 10px;

}
@media (max-width: 1470px) {
.card-wrap {
  width: 80%;
max-width: 500px;


}

@media (max-width: 990px) {
display: flex;
background-color: initial;

flex-direction: column;
align-items: center;
flex-wrap: wrap;
}

`

const HeaderText = styled.h1`
  color: #11A3E1;
  font-size: 2rem;
  font-weight: 700;
  margin-top: -20px;
  margin-left: 5px;
  
`



const TextWrapper = styled.div`
background-size: cover;
height: 220px;
width: 100%;
background-color: #2B2B2B;
margin-top: -33px;
padding:12px;
padding-top: 20px;

  h1, p {
    color: white;
  }
  p {
    font-size: 1rem;
    margin-top: 4px;
  }
  a {

    img {
      color: white;
    text-decoration: none;
    }
    h4 {
      :hover {

      color: silver;
      }
    }
  }
  div {
    display: flex;
    margin-bottom:-18px;
  }

  
`


const Techbg = styled.div`
height: 85px;


`


